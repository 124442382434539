<template>
  <div>
    <b-overlay :show="show" no-wrap fixed z-index="9999">
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="dark"></b-spinner>
          <b-spinner type="grow" variant="dark"></b-spinner>
          <b-spinner small type="grow" variant="dark"></b-spinner>
        </div>
      </template>
    </b-overlay>
    <router-link to="/admin/settings" class="btn btn-success">بازگشت</router-link>
    <b-card title="General setting" class="mt-3">
      <form @submit.prevent="itemEdit" id="edit-item">
        <div v-for="item in items.image" :key="item.id">
          <b-form-group :label="item.label" :label-for="item.key">
            <input :id="item.key" :name="item.key" type="file">
          </b-form-group>
        </div>
        <div class="row">
          <div class="col-md-4" v-for="item in items.text" :key="item.id">
            <b-form-group v-if="item.key == 'free_shipping_amount'" :label="item.label" label-for="price">
              <b-form-input id="edit-price" :title="item.key" v-model="price"></b-form-input>
            </b-form-group>
            <b-form-group v-else :label="item.label" :label-for="item.key">
              <b-form-input :id="item.key" :name="item.key" v-model="item.value"></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div v-for="item in items.textarea" :key="item.id">
          <b-form-group :label="item.label" :label-for="item.key">
            <b-form-textarea :id="item.key" :name="item.key" v-model="item.value"></b-form-textarea>
          </b-form-group>
        </div>
        <div class="row">
                <div class="col-md-4" v-for="item in items.boolean" :key="item.id">
                    <label class="custom-switch">
                        <input type="checkbox" class="custom-switch-input" v-model="item.value"
                          :disabled="disabled">
                        <span class="custom-switch-indicator"></span>
                        <span class="custom-switch-description">{{item.label}}</span>
                      </label>
                  </div>
            </div>
            <input v-for="(setting, index) in items.boolean" :key="index" type="hidden"  :name="setting.key" :value="setting.value || setting.value == 'on' ? 1 : 0"/>
        <button class="btn btn-success" type="submit" :disabled="disabled">Update</button>
        <input type="hidden" value="PATCH" name="_method">
      </form>
    </b-card>
  </div>
</template>
<script>
  import mixin from '../mixins/mixins'
  export default {
    mixins: [mixin],
    data() {
      return {
        items: [],
        show: true,
        url: '/api/admin/settings',
        free_amount: '',
        title: 'تنظیمات'
      }
    },
    methods: {
      loadSetting() {
        this.$axios.get(this.$root.baseUrl + '/api/admin/settings/general')
          .then(response => {
            this.items = response.data.data
            this.show = false
            this.price = this.items['text'].filter(item => item.key == 'free_shipping_amount')[0].value
          })
      },
      itemEdit() {
        this.disabled = true
        let form = document.getElementById('edit-item')
        let formData = new FormData(form)
        const price = document.getElementById('edit-price')
        if (price != null) {
          let org_price = this.price <= 999 ? this.price : this.price.replace(/\D/g, "");
          formData.append(`${price.title}`, org_price);
        }
        this.$axios.post(this.$root.baseUrl + this.url, formData)
          .then(response => {
            this.$root.success_notification(response.data.message)
            this.$root.$set(this.items.data.data, this.edit.index, response.data.data)
            this.$root.$emit('bv::hide::modal', 'edit-modal')
          })
          .catch(error => {
            this.$root.error_notification(error)
          })
          .finally(() => {
            this.disabled = false
          })
      },
    },
    created() {
      this.loadSetting()
    },
    
  }
</script>